import React, { Component } from "react";

class Contact extends Component {
  render() {

    return (
      <section id="contact" >

        <div className="row">  

              <div className="footer-col">
                <h4>More</h4>
                <p className="address">
                <a href="/about">About Us</a>
                  <br />
                </p>
                <h4>Questions?</h4>
                <p className="address">
                  <a href="mailto:help@refreshconnections.com">help@refreshconnections.com</a>
                  <br />
                </p>
              </div>
              <div className="footer-col">
                <h4>Legal</h4>
                <p className="address">
                  <a href="/terms">Terms and Conditions</a>
                  <br />
                  <a href="/privacy">Privacy Policy</a>
                  <br />
                  <a href="/cookies">Cookie Policy</a>
                  <br />
                  <a href="/principles">Member Principles</a>
                  <br />
                  <a href="/communitysafety">Community Safety</a>
                </p>
              </div>
        </div>
      </section>
    );
  }
}

export default Contact;
