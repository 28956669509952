import React, { Component } from "react";

class CommunityCare extends Component {
  render() {

    return (
      <section id="marketing" class="bg-blue">
        <div class="graphic relative">
          <img class="offside-top-right" src="./images/mask-white-border.png" ></img>
        </div>
        <div class="main left">
          <h2 class="top-right-padding">We care about supporting businesses that keep you safe — and avoiding the ones that don't.</h2>
          <br/>
          <h2>We think everyone deserves clean air and freedom from infection anywhere they receive healthcare.</h2>
          <br/>
          <h2>There is power in community.</h2>
          <br/>
          
          </div>
      </section>
    );
  }
}

export default CommunityCare;
