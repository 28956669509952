import React, { Component } from "react";
import Fade from "react-reveal";

class Buttons extends Component {
  render() {

    if (!this.props.callToAction) return null;

    return (
      <section id="marketing" class="bg-green">
        <div class="buttons">
          <Fade bottom duration={2000}>
            <h2>{this.props.callToAction}</h2>
            <h3>Download the app now!</h3>
            <div className="button-row">
              
              <div className="button-col">
                <a href="https://apps.apple.com/us/app/refresh-connections/id6502037766" className="button btn">
                  <i className="fa fa-apple"></i>&nbsp; iOS
                </a>
              </div>
              <div className="button-col" >
                <a href="https://play.google.com/store/apps/details?id=com.refreshconnections.app" className="button btn">
                  <i className="fa fa-android"></i>&nbsp;Android
                </a>
                <div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    );
  }
}

export default Buttons;
