import React, { Component } from "react";
import Fade from "react-reveal";

class MakeFriends extends Component {
  render() {

    return (
      <section id="marketing" class="bg-navy">
        <div class="graphic">
          <img  src="./images/flowerquestion.png" ></img>
        </div>
        <div class="main ">
        
         
          <h2 class="white">Did you know you can make Covid Conscientious friends online?</h2>
          
          </div>
      </section>
    );
  }
}

export default MakeFriends;
