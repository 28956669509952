import React, { Component } from "react";
import Fade from "react-reveal";

class LongCovid extends Component {
  render() {

    return (
      <section id="marketing">
        <div class="main left">
        <h3> Refresh Connections cares about people with Long Covid -- and the people that support them, care for them, and are looking to find a cure.</h3>
        <div class="graphic">
          <img src="./images/3hi.png" ></img>
        </div>
        <h3> Now everyone who cares can safely get together in a place that fosters conversations and encourages progress.</h3>
        </div>
      </section>
    );
  }
}

export default LongCovid;
