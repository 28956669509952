import React, { Component } from "react";
import Header from "./Header";
import Friendships from "./Friendships";
import Care from "./Care";
import Science from "./Science";
import Dating from "./Dating";
import NewPlace from "../Marketing/NewPlace";
import WeCare from "../Marketing/WeCare";
import RightNow from "../Marketing/RightNow";
import Buttons from "../Marketing/Buttons";
import Movie from "../Marketing/Movie";
import LongCovid from "../Marketing/LongCovid";
import Change from "../Marketing/Change";
import Refreshments from "../Marketing/Refreshments";
import CommunityCare from "../Marketing/CommunityCare";
import ConnectionsPyramid from "../Marketing/ConnectionsPyramid";
import MakeFriends from "../Marketing/MakeFriends";



import Contact from "./Contact";
import Footer from "./Footer";

import ReactGA from "react-ga";
import $ from "jquery";


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            foo: "bar",
            resumeData: {}
        };

        ReactGA.initialize("UA-110570651-1");
        ReactGA.pageview(window.location.pathname);
    }

    getResumeData() {
        $.ajax({
            url: "./resumeData.json",
            dataType: "json",
            cache: false,
            success: function (data) {
                this.setState({ resumeData: data });
            }.bind(this),
            error: function (xhr, status, err) {
                console.log(err);
                alert(err);
            }
        });
    }

    componentDidMount() {
        this.getResumeData();
    }

    render() {
        if (!this.props.data) return null;

        return (
            <div className="home">

                <Header data={this.state.resumeData.main} />
                <Movie />
                <NewPlace />
                <WeCare />
                <MakeFriends/>
                <RightNow  />
                <Buttons callToAction="Meet a new Covid Conscientious friend today"  />
                <LongCovid/>
                <Change/>
                <Refreshments/>
                <Buttons callToAction="Join the conversation at the Refreshments Bar"/>
                <CommunityCare/>
                <Buttons callToAction="Be part of the movement to create Covid Conscientious change"/>
                <ConnectionsPyramid/>
                {/* <Friendships data={this.state.resumeData.main} /> */}
                {/* <Care data={this.state.resumeData.main} /> */}
                {/* <Dating data={this.state.resumeData.main} /> */}
                {/* <Community data={this.state.resumeData.main} /> */}
                {/* <Science data={this.state.resumeData.main} /> */}
                <Contact data={this.state.resumeData.main} />
                <Footer data={this.state.resumeData.main} />
            </div>)
    }
}

export default Home;
