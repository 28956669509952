import React, { Component } from "react";

class AboutUs extends Component {
  render() {

    return (
      <div>
      <ul className="social">
                <a href="/" className="button btn project-btn">
                  <i className="fa fa-arrow-left"></i>Back
                </a>
                
              </ul>
      <div id="faqs">
        
        <h1>About us</h1>

<h2>Our experience</h2>

<p>We have a small core team of passionate and committed people with extensive experience in healthcare, online communication, and software engineering. We've spent a great deal of effort developing the app and are excited to be able to share it with you! We are designing services for the Covid Conscientious people who need this community now more than ever—including us.</p>

<p>The pandemic unifies the conscientious. We understand how it continues to harm health and relationships by making everyone else forget the most important part of being human: how to trust and care. We are not just designing the app—we will be using it and want it to be the best it can be for the new community it creates, which we are joining. We think you'll see this is the case in how we designed—and continue to tailor—the app towards the needs of the community.</p>

<h2> Why we created the app</h2>

<p>
Our greater Covid Conscientious community had a need: an app to connect us. Our community is dispersed, and we generally aren't able to meet each other at bars, restaurants, or events the way others can. Plus, current dating and friends apps don't work for us. A checkbox that says if you're still masking or if you're vaccinated doesn't fit our needs and experiences. If the culture of the community is not conscientious about Covid, that culture overwhelms any strategy to try to include us. 
</p>

<p>We also have a need to be able to share information about Covid without fear of suppression or overwhelm from Covid minimizers and disrupters - many of whom are well-funded and able to do things like successfully pass mask bans now.  This is why we built the Refreshments Bar community forum. It’s a place to share information and connect with others who have similar experiences, where your information is not suppressed or sold to advertisers (see our <a href="/privacy">Privacy Policy</a> for more on how we don’t share your data), and where Covid talk is way more than tolerated — it’s encouraged.</p>

<h2>How we design</h2>

<p>Covid Conscientiousness is not an afterthought here; it can't be. It has to inform everything we do because it is our culture. Cultures aren't supported by checkboxes added to one-size-fits-all dating and friends apps where only a vanishing minority of users embrace Covid Conscientiousness. We know how it feels for someone you've connected with to ask "Why are you still masking?" We know what it's like to have to say no to events and gatherings that don't take your safety into consideration, over and over again. We know the struggles of asking a friend to eat outside with you, only for the restaurant that you picked to not have any airflow in their so-called outdoor dining. Or for it to be raining and your friend to not get why you can't just eat inside this one time. And then for your "friend" to eventually stop asking you to do things. And by friend we also mean family members.
</p>

<h2>How we run the business</h2>

<p>We are in this for the long term. We aren't taking venture capital or other investments, and we are not designing the site to sell to the other dating/social media companies. We are not selling anyone's data, and that's stipulated in the terms.</p>

<p>We'd love to see Covid reduced to a small problem, one that in no way could justify the existence of a business around it. But we know that even if that happens, what we've all learned has changed us. And that gets us back to the trust and caring that has gone missing from relationships.</p>

<h2>Where we'd like to see our technology moving</h2>

<p>Our goals for the product are simple, and let’s talk about them in the same way we see the company’s mission growing.</p>

<p>First, we want to bring people together as Covid Conscientious friends. Sure, some of those friends will date. That’s how communities work! There is power in friendship of all types. Online. In-person. And romantic.</p>

<p>Friendship leads us to community. When enough of us band together, change can happen. We see that first happening locally. Here's an example. Let's say several hundred Covid Conscientious people all agree to shop at the same grocery store. If half of those people all agree to show up early one morning with respirators on to do their shopping together, the store will notice. Do that three weeks in a row and you can ask the store to wear respirators and add HEPA filters to their Monday morning business. If they don't, well there are other grocery stores…many who would love to become partners of people who move together with what are really very small and reasonable requests. They may even offer discounts for us to shop with our masks on.</p>

<p>It's not just grocery stores. It's other small businesses. It's dentists' and doctors' offices. With more people, it's hospitals. And corporations who haven't figured out yet that masks and ventilation keep their employees healthy.</p>

<h2>Come meet us during the soft launch</h2>

<p>During our soft launch (right now!!), we encourage you all to get to know us as Freshy on the app. Connect with Freshy and start up a conversation with us if we haven’t started one with you first.</p>

<p>We want your feedback, and you will see quickly how we incorporate your ideas into the app. We can’t wait to start Refreshing your Connections and creating positive change. </p>
          </div>
          </div>
    );
  }
}

export default AboutUs;
