import React, { Component } from "react";
import Fade from "react-reveal";

class NewPlace extends Component {
  render() {

    return (
      <section id="marketing">
        <div class="graphic">
          <img src="./images/fiver.png" ></img>
        </div>
        <div class="main center">
        <h2>The Refreshments Bar is our community forum!</h2>
        <h3 className="green">We want to talk about:</h3>
        <h3 className="green">
           <span class="blue">smart grocery shopping</span> • &nbsp;
           <span class="orange">dealing with family who don't get it</span> • &nbsp;
           <span class="navy">cleaning the air</span> • &nbsp;
           <span class="blue">risky roommates</span> • &nbsp;
           <span class="orange">best outdoor hangout spots</span> • &nbsp;
           {/* <span class="navy">tips and tricks</span> */}
           </h3>
        </div>
      </section>
    );
  }
}

export default NewPlace;
