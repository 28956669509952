import React, { Component } from "react";
import Fade from "react-reveal";

class WeCare extends Component {
  render() {

    return (
      <section id="marketing" class="bg-green">
        <div class="graphic relative">
          <img class="offside-top-right" src="./images/flower-navy-border.png" ></img>
        </div>
        <div class="main left padding-top-and-bottom">
          <h2 class="top-right-padding"> We care about <span class="white">you</span>.</h2>
          <br/>
          <h2>We care about <span >Covid.</span></h2>
          <br/>
          <h2>We care if you get <span class="">Covid</span>.</h2>
          <br/>
          <h2>Over and over and over <span class="">again</span>.</h2>
          
          </div>
      </section>
    );
  }
}

export default WeCare;
