import React, { Component } from "react";
import Fade from "react-reveal";

class NewPlace extends Component {
  render() {

    return (
      <section id="marketing">
        <div class="main center">
        <h2> Refresh Connections is a new place where Covid Conscientious people:</h2>
        <div class="graphic">
          <img src="./images/3group.png" ></img>
        </div>
        <h3 className="green">
           <span class="blue">meet</span> • &nbsp;
           <span class="orange">mingle</span> • &nbsp;
           <span class="navy">support</span> • &nbsp;
           <span class="blue">share</span> • &nbsp;
           <span class="orange">learn</span> • &nbsp;
           <span class="navy">get together </span>• &nbsp;
           <span class="blue">talk</span> • &nbsp;
           <span class="orange">get involved</span> • &nbsp;
           <span class="navy">plan</span> • &nbsp;
           <span class="blue">organize</span> • &nbsp;
           <span class="orange">rally</span> • &nbsp;
           <span class="navy">create change</span>
           </h3>
        </div>
      </section>
    );
  }
}

export default NewPlace;
