import React, { Component } from "react";



class Tips extends Component {
  render() {

    return (
<div>
      <div id="faqs">
          <h1>How To</h1>

          <p>Click a topic to be taken to the tutorial.</p>

          <ul><h3><a href="#connections">How to make your first connections</a></h3></ul>
          <ul><h3><a href="#profile">How to create your Profile</a></h3></ul>
          <ul><h3><a href="#filters">How to use Filters</a></h3></ul>  
          <ul><h3><a href="#visibility">How to use Visibility Preferences</a></h3></ul>   
          <ul><h3><a href="#recommendations">Our recommended approach to Filters and Visibility Preferences</a></h3></ul>   
          <ul><h3><a href="#refreshments">How to Connect from Refreshments</a></h3></ul>        
           
          <h2 id="connections">First Connections</h2>

          <h3 class="orange">Understanding Personal and Community Connections</h3>

          <p>When you join Refresh Connections, you can choose to make connections on the personal and/or the community sides of the app.</p>
          
          <p>The personal side of the app is where you go to make one-on-one connections. You'll find its sections by going to the <span style={{fontWeight: "bold"}}> Picks</span> and <span style={{fontWeight: "bold"}}>Chats</span> tabs on the lower left side of your tab bar. </p>
          <p>The community side of the app is where you go to connect on the community forum which we call the Refreshments Bar. Our Change Projects connect us to the Greater Movement. You'll find the <span style={{fontWeight: "bold"}}>Refreshments</span> and <span style={{fontWeight: "bold"}}>Change</span> tabs on the right side of your tab bar, just to the left of the Me tab. </p>
          

          <h3 class="orange">Your Profile, Picks, Likes, and Chats</h3>
          <p>Making connections on Refresh Connections starts with creating your Profile. By creating your profile, you are already on your way to making connections. You can show up in other people's Picks, and they can send you a Like. </p>
          <p>Your Picks tab also presents you with potential connections by showing you other members' profiles. When you see someone you want to connect with, you can Like their profile by using the heart in the bottom right. If it's someone you don’t see yourself connecting with at the moment, then use the bug button on the left to choose "Ignore for Now". The bug button also lets you choose to block someone if you can't see ever connecting with them. You can also report someone who might be a bad actor or a disruptor, an action we want you to take because it is key to the safety of our community. </p>
          <p>Making connections is always <span style={{fontWeight: "bold"}} >mutual</span>—if you like someone, they will need to like you back to make a connection, and vice-versa. People who have Liked you first will show up in your Chats tab in the top right corner where there is a heart button. If you have any outstanding Likes, you will see a red badge with a number on that button that tells you how many Likes to expect.</p>
          <p>When viewing profiles in Likes, you have the same options as in your Picks tab. You can Like them back with the heart on the right, or you can use the bug icon to choose Ignore for Now, Block, or Report.  
          If someone sends you a Like but you don't want to Like them back, then use the bug button on the left and choose “Ignore for now”. </p>
          <p>Once a connection has been made, either member can start a one-on-one chat with the other. That means if you like someone, that person might like you back and start a chat at the same time. Or they might Like you back and wait for you to initiate. </p>
          <p>Turn on your notifications in Me tab &gt; Settings to be notified when someone sends you a Like, Likes you back to connect with you, or sends you a message in Chats!
          </p>
          <div style={{textAlign:"center"}}>
          <img
                style={{maxWidth: "300px"}}
                src="images/likes-new-location.png"
                alt="Screenshot of the top third of the Chats tab of the app, where the word Chats is written in navy. On a new line there is a search button, an active chats button, an inactive groups button, and a heart button with a red badge with the number 1 on it."
              />
            <p style={{fontSize:"14pt"}}>Your Likes can be found in the heart button at the top right of your Chats tab.</p>
          </div>
          <h2 id="profile">How to make the most of your profile</h2>
          
      <p>Before filling out your profile, it's good practice to keep in mind our <a href="/communitysafety">Community Safety guidelines</a>. </p>
        <h3 class="orange">Section 1: The Basics</h3>
        <p>The Basics section includes your preferred first name or nickname, a freeform bio, your age, and how you want to identify your location on your Profile. You can choose to include your pronouns, height, job, school, hometown, and politics.</p>

        <p>The "Looking For" subsection allows you to specify the types of connections you are looking for on Refresh Connections. </p>
        <ul>

          <li>You can choose as many of the options as you'd like: friendship, romance, virtual connection, virtual connection only, job, housing/roommate, families. </li>
 <li> Note: If you have chosen <i>only</i> Romance (i.e., only a heart shows up on The Basics under Looking For when you view your profile), then you will only show up in Picks for people also looking for Romance. </li>
 </ul>

 <p>The Gender and Sexuality subsection involves both a freeform text area to describe your identity and a list of choices you can select to describe you that will be used when other members filter their Picks.</p>
 <ul>
  <li>You can choose as many, or as few, gender and sexuality options as make sense for you. Anything that doesn't fit into one of the provided choices can be explained in the freeform box.</li>
 <li>There is a toggle button that lets you choose whether you want the Gender and Sexuality multiple choice selection options to appear on your Profile. Whether you choose to show these on your Profile or not, these choices are used when other members filter their Picks. (So if you select "monogamous", but choose not to show your gender and sexuality choices on your profile, someone could still surmise this about you by filtering by "monogamous". Do not make any selections here that you would not want to make public otherwise.) </li>
 </ul>

 <p>Note: Our <a href="/privacy">Privacy Policy</a> and <a href="/communitysafety">Community Safety</a> guidelines emphasize to only reveal on the app what you would be comfortable making public because no one can control someone taking a photo of the app and spreading that information. That applies to your choices here, whether shown on your profile or not, and the information you provide in the freeform sections. Please take a moment to review those now. </p>

 <h3 class="orange">Section 2: Covid Behaviors</h3>

 <p>We are all here because we are conscientious about Covid, but everyone comes here with different life experiences, situations, and levels of caution. The Covid Behaviors sections helps start the process of defining how you approach Covid. We have included a freeform section for further explanation. Being open and honest about your risks is a huge part of being Covid Conscientious.
</p>


<h3 class="orange">Section 3: Long Covid Support</h3>

<p>Our early growth has made this section available much earlier than we thought. We will need ongoing growth for it to start really achieving its potential, but having it now makes both the growth and potential easier to see. We think there is already a lot of power in the six options. Look to the Refreshments Bar community forum to start understanding our vision for these choices - which we want to be shaped and guided by those in our community. </p>

<p>There is a toggle button that lets you choose whether you want the Long Covid Support multiple choice selection options to appear on your Profile. Whether you choose to show these on your Profile or not, these choices are used when other members filter their Picks. </p>

<h3 class="orange">Section 4: Let's Talk About</h3>

<p>This section is the fuel for conversation starters. When you fill it out, it's way easier to start a conversation!</p>

<h3 class="orange">Section 5: Photos</h3>

<p>Your first photo should show you! Potential connections want to know the real you and can't be introduced to you as a picture of a flower or a pet (though we'd love your pet photos to be included later in your photos). In line with our <a href="/privacy">Privacy Policy</a>, to look at other pictures of other people, you need to have pictures of yourself. </p>
<p>Your photos should be in line with our  <a href="/principles">Member Principles</a>. Graphic or sexual images do not belong on your Profile.</p>
<p> If you aren't ready to share photos yet, you can pause your Profile and connect with others in the Refreshments Bar community forum as you get to know Refresh Connections.</p>


      <h2 id="filters">How to use Filters</h2>

      <h3 class="orange">What are filters?</h3>
      <p>Of course, our community is growing and very diverse, so it makes sense to be able to search for a more specific group of people based on why you are connecting at the moment, whether it be to look for potential friends, romantic interests, Long Covid support, families, and more. That's where filters come in.       </p>
      <p>You use filters to narrow the pool of people you are looking to make connections with by any number of identifiers, which we will discuss below. You can set and reset filters, so if a particular combination leads to too broad or narrow a search, you can try a new combination right away. </p>
      
      <p>
        Every time you filter, think "I am looking for someone who is..." We've included that phrase at the top of the Filters screen as a reminder. Every category we go over next is simply a way to complete that sentence. 
      </p>

      <p>The more specific your search becomes, the more likely you will filter to an empty result. If you are looking for anyone within three hundred kilometers of central Toronto, you might see a number of potential connections. If you are looking for someone who is between the ages of 20 and 25, looking for Romance, lives within a hundred kilometers of central Toronto, and is caring for someone with Long Covid, you are more likely to come up with no results. But maybe! You're welcome to give more complicated searches a try. </p>
      <p>Remember, Refresh Connections is still in its soft launch - so if your filters return no Picks, that doesn't mean it never will. Growing our community will come next. For now, hit "Clear filters" and try again with looser filters to start Refreshing your Connections with other early joiners.</p>

      <h3 class="orange">Filtering by Age and Distance</h3>

      <ul><li>Your age range must include your age. </li>
 <li>You must share your location with us to filter by distance, either by allowing your phone to share your location once or by entering your coordinates. </li>
      </ul> 

      <h3 class="orange">Filtering by Connections</h3>

      <ul>
       <li>You can choose to filter for all of the connection types available at once (with the exception of Long Covid Support), or you can choose to filter by a specific connection type. </li>
        <li>You can only filter by Romance if you have chosen Romance as an option on your own Profile. </li>
      </ul>

      <h3 class="orange">Filtering by Covid Behavior</h3>

      <ul>
      <li>You can choose to filter by someone who has selected that they practice a specific Covid Behavior or by someone who has not selected a specific Covid Behavior. 
      </li>
        <li>For example, you might want to connect with someone who has chosen that they do not have routine daily exposures. 
        </li>
      </ul>

      <h3 class="orange">Filtering for Long Covid Support</h3>
      <ul>
        <li>You can only filter for Long Covid support as an individual filter option. It is not included in an All search. </li>
        <li>When you select Long Covid support, new selection options will appear with your choices.</li>
        <li>All other filters apply, so if you don't want to limit your Long Covid support options by age or gender and sexuality, then make sure to clear those filters. </li>
      </ul>

      <h3 class="orange">Filtering by Gender and Sexuality</h3>
      <ul>
        <li>You can search by "any" or "all". </li>
        <li>You can choose any number of the options available in any combination. </li>
        <li>If you choose All, the filter will only return members who match all of those choices. The "All" filter is very powerful!</li>
        <li>If you choose Any, the filter will return members than match any of your choices and are more likely to have a number of Picks available. </li>
        <li>As you can see, there are a lot of options for Gender and Sexuality filters! If searching for Romance and using these filters (especially the ALL filter), we expect you to return to the filters panel often and use different combinations to find compatible connections.</li>
      </ul>

      <h3 class="orange">Clear your filters!</h3>
      <p>Remember, Refresh Connections is new. We're still growing! Consider reaching out to people further away or in a wider age range. Clear your filters and start again if no Picks show up just yet. Plus, you can always connect with members at the Refreshments Bar community forum!</p>
      
      <h2 id="visibility">How to use Visibility Preferences</h2>

      <p>Creating a profile on Refresh Connections makes you visible to other Refresh Connections members. If you have specific qualifications for connections you’d like to make, you might choose to use the Profile Visibility Preferences to limit who can send you Likes.</p>
      <p>If you are open to all connections and want to experience fully the diversity of our community, we recommend you do not use Profile Visibility Preferences and leave your profile visible to all Refresh Connections members (you will still choose who to connect with by Liking people back or not).</p>
      <p>It is important to remember that Refresh Connections uses the identifiers users have selected for themselves, so visibility preferences are not guarantees. Users can also change these at any time. Do not post anything in your Profile that you would not be comfortable with members outside of your preferences seeing as well. Please remember to read our <a href="/communitysafety" >Community Safety Guidelines</a> for best practices on staying safe online.</p>
      <p>These preferences will apply to all types of connections in Picks. If you are looking for friendships, virtual connection, job, housing, families, etc, you might choose to limit your visibility less than someone who is only connecting for romance. </p> 
      <p>However, Visibility Preferences are specific for Picks on the personal connections side of the app. If you choose to make your Profile visible on the Community side of the app, any member who has also chosen to turn on their <a href="#refreshments">Connect from Refreshments</a> setting can see your Profile and send you a Like.</p>

      <h3 class="orange">Age range preference</h3>
      <p>You can limit who sees your profile to the age range you are filtering by (your age range must be turned on in your filters to use this).</p>
      
      <h3 class="orange">Distance preference</h3>
      <p>(This one is still in development!)</p>

      <h3 class="orange">Gender and Sexuality Identity preferences</h3>
      <p>Just like in filters, Visibility Preferences are highly customizable. You can set Limits to “Any” or “All”. You can use Limits to only show your profile who have included a specific identifier, or to only show your profile to people who have NOT included a specific identifier. </p>
        <p>Be careful not to prevent your profile from being shown to anyone — the app will do its best to prevent impossible combinations from being made but won't be able to catch them all!</p>
      
      <p>If you need help coming up with a bespoke combination of Visibility Preferences, our team would be happy to assist. Email us at <a href="mailto:help@refreshconnections.com">help@refreshconnections.com</a>.
      </p>

      <h2 id="recommendations">Recommendations for Filters and Visibility Preferences</h2>

      
      <h3 class="orange">Visibility Preferences: "Set and Forget"</h3>
      <p>Visibility Preferences are there for you to use, but because they don’t affect who you are seeing in your Picks, we assume they will be more of a “set and forget” situation. Meaning you’ll decide on some Visibility Preferences, and then forget about them. That is absolutely fine and intended — just be careful not to over-limit who can see your profile! After all, Refresh Connections is about making connections! Limiting your profile visibility will mean you should be more active in sending Likes to make connections, but profile visibility is a two-way street. Too many restrictions and you and a new best friend might miss out on meeting one another!</p>
      <h3 class="orange">Filters: "Set and Reset"</h3>
      <p>On the other hand, we expect you to come back to Filters often. All kinds of connections can be made here. Who you are looking for in a friend might be different than who you are looking to be romantic with. We have a growing number of members looking to connect about Long Covid and Families.  You might have be more restrictive about Covid Behaviors with people you’d like to hang out with in person than virtual connections. We recommend you either head to the filters section every time you sit down to find some new Picks with a specific idea of who you are looking for in mind, or leave your filters less restrictive and look for all sorts of connections at once!</p>


      <h2 id="refreshments">Connect from Refreshments</h2>
      <p>You can also choose to have other members view your profile—and subsequently connect—from your posts and comments at the Refreshments Bar. </p>
      <p>To view and connect, both members must have this setting turned on. Anyone with this setting turned on can see the Profile of anyone who also has this feature turned on, independent of anyone’s filter settings or Visibility Preferences, which only work in the Picks and Chats sections (the Personal Connections) side of the app. 
      </p>
      <p>If you can see someone’s profile but don’t have the option to Like them, you have either already connected with them and the are in your Chats, or you already sent them a Like. 
      </p>

      <div style={{textAlign:"center"}}>
          <img
                style={{maxWidth: "300px"}}
                src="images/connect-from-refreshments.png"
                alt="Screenshot of the Settings Page of the app"
              />
            <p style={{fontSize:"14pt"}}>Turn on Connect from Refreshments in your Me tab &gt; Settings.</p>
          </div>
     
      

      <br/>
      <br/>
      <br/>
      <br/>


      
    
</div>
</div>
        )
  }
}

export default Tips;


