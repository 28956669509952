import React, { Component } from "react";



class Privacy extends Component {
  render() {

    return (
<div>
<ul className="social">
                <a href="/" className="button btn project-btn">
                  <i className="fa fa-arrow-left"></i>Back
                </a>
                
              </ul>
      <div id="legal"><h1>Refresh Connections Privacy Policy</h1>

<h3>Version 1.13, (Updated July 3, 2024)</h3>

<h1>1. Introduction</h1>

<p>The Refresh Connections <a href="https://refreshconnections.com/privacy">Privacy Policy</a> is a part of our Terms and Conditions of Use along with the Refresh Connections <a href="https://refreshconnections.com/Cookies">Cookie Policy</a>, the Refresh Connections <a href="https://refreshconnections.com/Principles">Member Principles</a>, and the Refresh Connections <a href="https://refreshconnections.com/communitysafety">Community Safety</a> recommendations.</p>

<p>It is the policy of Refresh Connections, LLC (“Refresh,” “we,” “us” and “our”) to respect your privacy regarding any information we may collect from you or which you may provide to us, in the circumstances set out below. Accordingly, we have developed this privacy policy in order for you to understand how we collect, use, communicate, disclose, safeguard and otherwise make use of your personal information and data (also called “Personal Data”).</p>

<p>This is the Privacy Policy for everything Refresh Connections, LLC offers, including our Google and Apple apps, our website, and any events or other services we operate (collectively, the “Services”). For some offerings we may require a unique privacy policy which will apply in addition or as a substitution for this one, as described in that specific policy.</p>

<p>The App and websites are global, and your information will be sent to and used in the United States regardless of the country you reside in. This Policy explains how we protect your personal data when we transfer it overseas, so please read very carefully!</p>

<h1>2. Who We Are</h1>

<p>The Refresh Connections Services are operated by Refresh Connections, LLC, which is the data controller of the Personal Data collected and processed through the Services. The full contact details for Refresh Connections, LLC is listed below in section 15.</p>

<h1>3. Data Protection Officer</h1>

<p>Refresh Connections, LLC has designated a Data Protection Officer and they can be reached by email at <a href="mailto:DPO@refreshconnections.com">Data Protection Officer</a> or by mail at the following address: Refresh Connections,LLC, attn: Data Protection Officer, 64 Bleecker Street, Suite 226, New York, New York 10012.</p>

<h1>4. Personal Data We Collect</h1>

<p>When you download the app or use the website, we want to help you start using it to refresh your connections, such as making new friends or participating in the community. To do that we need information about you, such as basic profile details like age and your preferred name, the types of people you&#8217;d like to meet, and the activities in the community you&#8217;d like to participate in. That information can come from your sharing your information with us directly, from third party sites sharing information you&#8217;ve authorized, or information we collect through your use of our services such as access logs. Read on for the types of information we collect.</p>

<h2>4a. Personal Data You Provide</h2>

<h3>Creating Your Account</h3>

<p>When you create your account you will provide us with your real name, phone number, and email address, as well as any other details the service requires. We will only reveal your real name, phone number or email address consistent with the terms of this Privacy Policy. See the next section for information on how and when we reveal such information.</p>

<h3>Creating Your Profile</h3>

<p>When you create your profile, you share your preferred first name, also known as your nickname, and have the option of sharing a number of personal traits, including sexual orientation, religious beliefs, political opinions, behaviors regarding communicable diseases, and others. You may also consent to share your geolocation with us to make it easier to find friends that are near you.</p>

<p>Your preferred name is always visible on your profile. We never share your last name or your first name if it is different from your preferred name. Otherwise, what you choose to share on your profile is visible for other Refresh Connections users to see. If you don&#8217;t want to share anything on the profile, then don&#8217;t complete the corresponding optional section when you create your profile.</p>

<h3>Making Public Posts</h3>

<p>The Refreshments Bar is a place where you can comment publicly. You will choose a name for posting there that can be different than the preferred name you use on your profile. However, this name will appear on your profile and will be available to anyone who can see your profile, such as friends with whom you&#8217;ve connected.</p>
<p>Please note that we may ask for your consent to republish any comments or posts you make on the Refreshments Bar or in feedback to the Refresh Connections team.</p>

<h3>Making Purchases</h3>

<p>When you choose to make transactions of products or services offered on Refresh Connections, you will have to provide payment information in order to complete your purchase. If you are using our Apple or Google Play app then their respective billing services will handle the transaction. Otherwise you will provide Refresh Connections with the necessary information such as credit or debit card numbers or other financial information so that our payment processors can complete the transaction.</p>

<h3>Refresh Connections Improvement Studies or Promotions</h3>

<p>You may choose to participate in surveys or focus groups to help us improve Refresh Connections or promotions to take advantage of special offers. In those settings we collect your responses to our questions and your free-response answers to prompts.</p>

<h3>Taking Actions</h3>

<p>You share information with us when you participate in chats, share photos, or provide information on other users such as contact details of a friend to invite them to join the Refresh Connections community. You also share information when you contact customer support.</p>

<h2>4b. Personal Data Others Provide</h2>

<h3>Partners and Linked Services</h3>

<p>We may receive information about you from our partners where our ads are published on a partner’s service (in which case they may pass along details on a campaign’s success). Where legally allowed, we can also receive information about suspected or convicted bad actors from third parties as part of our efforts to ensure Refresh Connections users’ safety and security.</p>

<h3>Integrations, such as Social Media</h3>

<p>You may decide to share information with Refresh Connections through your social media account, including uploads like photos or messages.</p>

<h3>Other Users</h3>

<p>When you interact with other users on the service, for example in chats with friends or in the Refreshments Bar community forum, they may share information about you as they use the Services. Other users may also submit a report involving you.</p>

<h2>4c. Personal Data Generated or Collected Using the Services</h2>

<h3>Log and Usage Data</h3>

<p>We log certain information when you access and use the Services. This information may include your IP address, browser type, user-agent string, operating system, referral URLs, device information (device ID&#8217;s), device settings, mobile carrier name, pages visited, links clicked, the requested URL, and search terms.</p>

<h3>Cookies</h3>

<p>See our <a href="https://refreshconnections.com/cookies">Cookie Policy</a> for how Refresh Connections uses cookies and your associated choices, including disabling them.</p>

<h1>5. How We Use Your Information</h1>

<p>Our primary goal of using your information is to create an enjoyable and productive user experience for you as you find friends and a community that approaches communicable diseases such as Covid in a conscientious way. We also want to keep you, your friends, and our community safe and as free as possible from disruptors or other bad players.</p>

<p>Here is a listing of what kinds of data processing we do, what data we need to do it, where that data comes from, and the legal basis for our processing of it.</p>

<table>
<colgroup>
<col style={{textAlign: "left"}}/>
<col style={{textAlign: "left"}}/>
<col style={{textAlign: "left"}}/>
<col style={{textAlign: "left"}}/>
</colgroup>

<thead>
<tr>
	<th style={{textAlign: "left"}}> Processing Purpose </th>
	<th style={{textAlign: "left"}}> Data </th>
	<th style={{textAlign: "left"}}> Source </th>
	<th style={{textAlign: "left"}}>Legal Basis </th>
</tr>
</thead>

<tbody>
<tr>
	<td style={{textAlign: "left"}}> To offer you the Refresh Connections Services </td>
	<td style={{textAlign: "left"}}>Name, email address, date of birth, and location</td>
	<td style={{textAlign: "left"}}>You provide name, email address, and date of birth. You can choose to provide location or allow us to gather it from your device. </td>
	<td style={{textAlign: "left"}}> Contract</td>
</tr>
<tr>
	<td style={{textAlign: "left"}}> To Identify you so we can establish your account and maintain network integrity, including the safety and security of users. </td>
	<td style={{textAlign: "left"}}> Phone number, age, device information </td>
	<td style={{textAlign: "left"}}>You provide phone number and age, device information is obtained from the device and app on which you obtain the Services</td>
	<td style={{textAlign: "left"}}> Legitimate business interests to be able to offer you the core Services features </td>
</tr>
<tr>
	<td style={{textAlign: "left"}}>To present you with friend-making opportunities </td>
	<td style={{textAlign: "left"}}>Profile features including gender, religion, ethnic background, sexual preferences, photos, and others. </td>
	<td style={{textAlign: "left"}}>You provide us this information</td>
	<td style={{textAlign: "left"}}> Consent, contractual, legitimate business interest to be able to offer you the core Services features </td>
</tr>
<tr>
	<td style={{textAlign: "left"}}>To allow you to pay for premium services and special events unrelated to the Refresh Connections Apple and Google Apps</td>
	<td style={{textAlign: "left"}}> Payment card details </td>
	<td style={{textAlign: "left"}}> You provide us this information </td>
	<td style={{textAlign: "left"}}> Contract </td>
</tr>
<tr>
	<td style={{textAlign: "left"}}> To send you marketing about services, offers, and special events both on and away from the Srvices </td>
	<td style={{textAlign: "left"}}> Name, phone number, email address, postal address, social media handles such as Instagram </td>
	<td style={{textAlign: "left"}}>You provide us this information</td>
	<td style={{textAlign: "left"}}> Consent and legitimate business interest to be able to promote Refresh Connections</td>
</tr>
<tr>
	<td style={{textAlign: "left"}}> To analyze your use of the app to improve your app experience and create new features and services </td>
	<td style={{textAlign: "left"}}> Log and usage data, including IP address, referral domain, pages accessed and foci of interest on those pages, images, video, profile information </td>
	<td style={{textAlign: "left"}}> We obtain log and usage information from device and app with which you use the service. You provide images and videos. </td>
	<td style={{textAlign: "left"}}> Legitimate business interest to improve app experience and create new in-demand features </td>
</tr>
<tr>
	<td style={{textAlign: "left"}}> To provide you customer support </td>
	<td style={{textAlign: "left"}}> Name, Email address, social media name, phone number </td>
	<td style={{textAlign: "left"}}> You provide name, email address, social media name, and phone number </td>
	<td style={{textAlign: "left"}}> Legitimate business interests to provide support </td>
</tr>
<tr>
	<td style={{textAlign: "left"}}> To respond to your reports of violations of the terms and conditions of use, or your reports of danger to yourself or other users </td>
	<td style={{textAlign: "left"}}> Name, account details, profile information, message content including photos and videos, usage and device data including IP address and session data </td>
	<td style={{textAlign: "left"}}> You provide us your registration details, profile information, messages, and photographs and videos. We obtain IP address and session data from your device </td>
	<td style={{textAlign: "left"}}> Legitimate business interests to block infringement of Terms and Conditions of Use and maintain a safe service and it’s continued integrity, Vital Interests if someone life is in peril, Legal Obligation to comply with the law </td>
</tr>
<tr>
	<td style={{textAlign: "left"}}> To advertise on other websites and measure effectiveness </td>
	<td style={{textAlign: "left"}}> Data about your use of our app and website, whether you have downloaded the app and/or created an account, your age and gender, device type </td>
	<td style={{textAlign: "left"}}> We obtain age and gender from you and obtain the other information from your device or browser that you use to access the Services </td>
	<td style={{textAlign: "left"}}> Consent as indicated in the Privacy or Cookie settings of your browser, device, or the setting within the Services </td>
</tr>
<tr>
	<td style={{textAlign: "left"}}>To get your feedback on our products and services</td>
	<td style={{textAlign: "left"}}> Email address, cell phone, and direct through the messaging service </td>
	<td style={{textAlign: "left"}}>We obtain your email address, cell phone, and can contact you through the app</td>
	<td style={{textAlign: "left"}}> Legitimate business interests to improve the app and develop new, desired features </td>
</tr>
<tr>
	<td style={{textAlign: "left"}}>For legal purposes, such as defending legal claims, preserving rights, and protecting people from harm</td>
	<td style={{textAlign: "left"}}> Any and all information relevant to the issue, including messaging, customer support contact </td>
	<td style={{textAlign: "left"}}> You provide us with message and customer support inquiries, we obtain other informal from your use of the Services and your device.</td>
	<td style={{textAlign: "left"}}> Legitimate business interests to protect your and our legal rights and defend legal claims </td>
</tr>
</tbody>
</table>

<h1>6. How We Share Information</h1>

<h2>6a. With Other Users: How Visibility Works on the Refresh Connections Services</h2>

<p>Our primary goal is to help you connect with new friends and join the Refresh Connections community of people wanting to create and live in a safer and more equitable world by being smart about Covid and other communicable diseases.</p>

<p>Refresh Connections makes it possible for you to share information with potential friends and the Refresh Connections community. There are many ways you can do this, from creating your profile, messaging other members, and participating in discussions in the Refreshments Bar (community forum). Refresh Connections will share your profile with other users seeking friends. And you will make your profile available to other users when you participate in group discussions or the Refreshments Bar (community forum).</p>

<p>If someone submits a report involving you, such as a claim that you were involved in a terms of use violation, then we may share the response we took as a result of evaluation of the report.</p>

<h3>What you reveal is up to you.</h3>

<p>As discussed in section 4, it is your choice what you reveal on your profile. It is also your choice what you message in your Chats and you post on the Refreshments Bar community forum—and when you post you share that personal data with your explicit consent.</p>

<p>We want you to be excited about meeting people via the Services and getting to know them. We also want you to understand that everything you post here can become public—even on the sections of the app that are designed for one-on-one and small group conversations. For example, another Refresh user might screenshot a chat and post it outside of the Services in violation of our Terms and Conditions of Use</p>

<p>So be careful revealing what you are keeping personal off the Services and take your time getting to know how others are using the Services. It&#8217;s best to assume your words, pictures, and videos—and everything in them including metadata and everything in the background of the images—might someday become public.</p>

<h3>Making New Connections and Messaging in Conversations</h3>

<p>The Picks, Likes, and Chats section of the Refresh Connections app are about making new one-on-one and small group connections. Your profile will be shown to potential connections, including those who don&#8217;t pick you or like you back if you pick them. If you make a connection (both of you like the other), then your profile will remain available for them to view as you have conversations in chats. You should assume that anyone can screenshot and share your profile anywhere they want.</p>

<p>In Chats you can participate in one-on-one discussions with people who you have matched with, and you can elect to participate in group discussions that will contain at least one other person you have matched with. In Group discussions, the people involved who you have not matched with may not be able to see your profile based on your choices in settings, so your introduction to them may be dependent on your shared connection.</p>

<p>Whether posting in one-on-one or group chats, your messages will become part of the message stream of the people involved in the chat. You should assume that anyone can screenshot and share that conversation anywhere they want.</p>

<h3>The Refreshments Bar and Posting in the Refresh Connections Community</h3>

<p>The Community tab takes you to the Refreshments Bar community forum part of the Services. It functions as a community forum where you can start and respond to announcements and messages viewed by the entire Refresh Connections community.</p>

<p>You can choose a handle name for posting in the Refreshments Bar community forum but that handle is available for viewing on your Profile, so it is visible to anyone who is able to view your profile.</p>

<p>You should assume that anyone can share using app functionality—or by screenshot—any post that you make on the Refreshments Bar.</p>

<h2>6b. With Law Enforcement</h2>

<p>We may disclose your information in accordance with or when required by law, regulation, legal process, or governmental request, including but not limited to, meeting national security or law enforcement requirements. To the extent the law allows, we will attempt to provide you with prior notice before disclosing your information in response to such a request.</p>

<h2>6c. In an Emergency</h2>

<p>We may share your information if we believe it is needed to prevent serious and imminent harm to a person.</p>

<h2>6d. To Enforce Our Rights</h2>

<p>We may share your information if your actions are inconsistent with our terms of service, or to protect the rights and safety of other users, the services, and ourselves.</p>

<h2>6e. In Aggregate or De-identified</h2>

<p>We may share your information in a way that has be anonymized via aggregation or de-identification, such as by showing how many people have viewed a post, or to let an advertiser know how many people have engaged with their ad, or to allow advertisers to customize any ad campaigns we run.</p>

<h2>6f. With Your Consent or at Your Request</h2>

<p>We may ask for your consent to share your information with third parties and make it clear why we want to share the information, such as for special offers from third parties for our members.</p>

<h1>7. How We Protect Data Transfers</h1>

<p>We design Refresh Connections to help protect you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction of your personal data. For example, we encrypt your data at rest, and use the HTTPS encrypted protocol when the data is transmitted.</p>

<h2>International Data Transfers</h2>

<p>Refresh Connections, LLC is based in the United States and we process and store information on servers located in the United States. We may store information on servers and equipment in other countries depending on a variety of factors, including the locations of our users and service providers. By accessing or using the Services or otherwise providing information to us, you consent to the processing, transfer, and storage of information in and to the United States and other countries, where you may not have the same rights as you do under local law.</p>

<p>When we transfer the personal data of users in the EEA, UK and/or Switzerland, we rely on the Standard Contractual Clauses approved by the European Commission for such transfers or other transfer mechanisms deemed &#8216;adequate&#8217; under applicable laws.</p>

<h1>8. How We Retain Data</h1>

<p>We store the personal data we collect from you for as long as it is necessary for the purpose(s) for which we originally collected it (as delineated in section 4 above) and as permitted or required by applicable law. We retain your messages in chats for 3 months or longer based on the settings options you choose in your app. If two or more people are in a chat with a person that has selected a longer retention period, all of the messages in the chat will be retained based on the longer retention time. Posts in the Refreshments Bar community forum are retained for their educational and historical value.</p>

<p>When you delete your account, either through the app or by request to us, your account is deactivated and your profile is no longer viewable to others in the app, and the identity of your messages in chats and posts on the Refreshment Bar will be labeled <em>deactivated</em>, with the message remaining to preserve conversational meaning and flow. You have 28 days to change your mind about or correct an accidental account deletion, after which we will begin the process of deleting your personal data from our systems, except as explained in the next paragraph.</p>

<p>We may retain certain personal data for legitimate business purposes and/or if we believe doing so is in accordance with, or as required by, any applicable law. For example, if you violate our policies and your account is suspended or banned, we may store the identifiers used to create the account (such as email and phone number) to prevent you from creating new accounts. We may also store a record of the activities that led to the block in case of legal actions concerning the block.</p>

<p>Be aware that any personal data you have shared with others may be copied or stored by them outside of our control. We do not accept liability for this.</p>

<h1>9. Your Rights and Choices</h1>

<h2>Your Choices</h2>

<p>Refresh Connections wants you to control your information and has the following options and tools available to you to help:</p>

<h3>Settings in the Refresh Connections App:</h3>

<p>Tools and account settings can help you access, rectify, or remove information that you provided to us and that is associated with your account directly within the service. For example, in the Profile section of the Me tab, you can choose what to display and edit your personal data related to finding friends and the Refreshments Bar community. If you have any questions on those tools and settings, please contact our team <a href="mailto:help@refreshconnections.com">here</a>.</p>

<h3>Settings on the Device that Hosts the Google Play or Apple App</h3>

<p>Apple and Google platforms have permission systems for specific types of device data and notifications, such as phone contacts, pictures, location services, push notifications, and advertising identifiers. You can change your settings on your device to either consent or oppose the collection or processing of the corresponding information or the display of the corresponding notifications. Of course, if you do that, certain services may lose functionality.</p>

<h3>Pause Profile</h3>

<p>Pausing your profile is useful If you would like to stop showing your profile to potential friends but still chat with friends you have made and participate in the Refreshments Bar community. You can pause your profile directly on the App by going to the Me tab, clicking the Settings option, and clicking the <em>Pause profile</em> option under Profile actions.</p>

<h3>Profile Deactivation</h3>

<p>You can temporarily deactivate your Refresh Connections account directly on the app by going to the Me tab, clicking the Settings option, and clicking <em>Deactivate your profile</em> under the Profile Actions section.</p>

<p>When you deactivate your profile, your account is deactivated and your profile is no longer viewable in the app, and the identity of your messages in chats and posts on the Refreshment Bar will be labeled <em>deactivated</em>.</p>

<p>To activate your account after a period of deactivation, go to the Me tab, click the Settings option, and click <em>Activate your profile</em> under the Profile Actions section.</p>

<h3>Account closure</h3>

<p>You can permanently close your Refresh Connections account directly on the app by going to the Me tab, clicking the Settings option, and clicking <em>Delete your account</em> under the Profile Actions section.</p>

<p>When you close your account, either through the app or by request to us, your account is deactivated and your profile is no longer viewable in the app, and the identity of your messages in chats and posts on the Refreshment Bar will be labeled <em>deactivated</em>.</p>

<p>If you need help closing your account, contact <a href="mailto:help@refreshconnections.com">Refresh Connections Customer Service</a> and request that your account be closed.</p>

<p>Please see Section #8 Data Retention for what happens to your data when you close your account.</p>

<h3>Uninstall.</h3>

<p>You can stop all information collection by an app by deleting it using the standard uninstall process for your device. Remember that uninstalling an app does NOT close your account. To close your account, please use the corresponding functionality on the service.</p>

<p>If you have deleted your app without closing your account, or need help closing your account, contact Refresh Connections <a href="mailto:help@refreshconnections.com">Customer Service</a> and request your account be closed.</p>

<h2>Your Rights</h2>

<p>Some people have rights based on where they live to correct or rectify personal information, opt out of certain advertising practices, and/or withdraw consent for processing personal data where they have previously granted consent. We explain how to exercise each of these rights in the section below. Refresh Connections does not discriminate against users for exercising their rights under data protection laws.</p>

<p>We also want you to be aware of your privacy rights. Depending on where you live, you may have the right to:</p>

<ul>
<li><p>In certain circumstances you can exercise the right to request a copy of the information we keep about you in portable format by contacting our <a href="mailto:DPO@refreshconnections.com">Data Protection Officer</a>.</p></li>
<li><p>You can exercise the right to request that we delete personal information we keep about you by submitting a request to our<a href="mailto:DPO@refreshconnections.com">Data Protection Officer</a>. Deleting personal information will render portions or all of the app services we offer inoperable.</p></li>
<li><p>Our service allows you to correct most of the information you provide us by editing your profile. If you believe anything you can&#8217;t edit is inaccurate, you may contact us at <a href="mailto:DPO@refreshconnections.com">Data Protection Officer</a></p></li>
<li><p>You may also have the right and you may contact our <a href="mailto:DPO@refreshconnections.com">Data Protection Officer</a> to request that we restrict certain processing. Restricting processing will render portions or all of the app services we offer inoperable.</p></li>
</ul>

<p>We reserve the ability to ask you to provide identity proof before responding to any of the above requests. We do this to protect you and all of our users.</p>

<p>We may reject requests if we cannot identify you based on the proof that you provide or if other factors contribute, such as an illegal request, a request that may cause damages to Refresh Connections, LLC via infringing on trade secrets or intellectual property, or a request that puts the privacy of another user at risk.</p>

<p>If any portion of your request involves information from another user, then the other user will also need to contact us and make a request for the same information, along with providing identity proof and the other steps listed above.</p>

<p>We also cannot honor requests to restrict the processing of personal information if it prevents us from offering you Refresh Connections services, for example, by removing age or other identifying information required by the service.</p>

<h3>If you are a resident of Virginia, Colorado, or Connecticut, USA:</h3>

<p>You may be able to appeal a denial by contacting our <a href="mailto:DPO@refreshconnections.com">Data Protection Officer</a>, and explicitly referencing &#8220;Privacy Request Appeal.&#8221; If you have concerns about the result of your appeal you may contact the attorney general for your state.</p>

<p>Additionally, please note that we do not sell your personal data or use it for targeted advertising or profiling, so no opt-out choice is necessary.</p>

<p>We reserve the ability to ask you to provide identity proof before responding to any of the above requests. We do this to protect you and all of our users.</p>

<h3>For California Users</h3>

<p>The California Consumer Privacy Act (&#8220;CCPA&#8221;), as amended, requires us to provide California residents with some additional information, which we address in this section.</p>

<p>Since we are launching in conjunction with this version of the privacy policy, we anticipate collecting the following categories of personal information from California residents, depending on the Services used. As the service ages, we anticipate collecting this over any preceding period up to 12 months:</p>

<ul>
<li>Identifiers and account information, like your Refresh Connections username, email address, phone number, IP address, and cookie information.</li>
<li>Commercial information, including information about transactions you undertake with us.</li>
<li>Internet or other electronic network activity information, such as information about your activity on our Services and limited information about your activity on the services of advertisers who use our advertising technology.</li>
<li>Geolocation information based on your IP address.</li>
<li>Your messages with other users (e.g., Chats and the Refreshments Bar community forum).</li>
<li>Audiovisual information in pictures, audio, or video content submitted to Refresh Connections.</li>
<li>Professional or employment-related information or demographic information</li>
<li>Inferences we make based on other collected data, for purposes such as recommending content, advertising, and analytics.</li>
</ul>

<p>You can find more information about (a) what we collect and sources of that information in Section 4: Personal Data We Collect, (b) the reasons we process that information in Section 5: How We Use Information, and (c) the categories of third parties with whom we share that information in Section 6: How We Share Information.</p>

<p>If you are a California resident, you have additional rights under the CCPA. You have the right to opt out of any sales or sharing of your personal information, although please note below that we do not sell your personal data or use it for targeted advertising or profiling, so no opt-out choice is necessary for Refresh Connections. You have the right to request access to and information about our data practices and to request deletion or correction of your personal information as well as the right not to be discriminated against for exercising your privacy rights. Refresh Connections does not &#8220;sell&#8221; or &#8220;share&#8221; personal information as those terms are defined under the CCPA. We do not use or disclose sensitive personal information except to provide you the Services or as otherwise permitted by the CCPA.</p>

<p>You may exercise your rights to access, delete, or correct your personal information as described above.</p>

<p>We reserve the ability to ask you to provide identity proof before responding to any of the above requests. We do this to protect you and all of our users. You may also make a rights request using an authorized agent. If you submit a rights request from an authorized agent who does not provide a valid power of attorney, we may ask the authorized agent to provide proof that you gave the agent signed permission to submit the request to exercise rights on your behalf. In the absence of a valid power of attorney, we may also require you to verify your own identity directly with us or confirm to us that you otherwise provided the authorized agent permission to submit the request. If you have any questions or concerns, you may reach us using the methods described under &#8220;Your Rights and Choices&#8221; or by emailing our <a href="mailto:DPO@refreshconnections.com">Data Protection Officer</a>.</p>

<h3>For EEA and UK Users</h3>

<p>Users in the EEA and UK have the right to request access to personal data, rectification of incorrect or incomplete personal data, or erasure of their personal data if certain grounds are met; to data portability in certain circumstances; to request restriction of processing; to object to processing; and to withdraw consent for processing where they have previously provided consent. These rights can be exercised as described above. EEA users also have the right to lodge a complaint with their local supervisory authority.</p>

<p>We collect and process personal data in the EEA and UK as delineated in Section 5 above with the associated legal bases as listed in the chart.</p>

<p>Additionally, please note that we do not sell your personal data, or use it for targeted advertising or profiling, so no opt-out choice is necessary.</p>

<h3>Additional Concerns</h3>

<p>We reserve the ability to ask you to provide identity proof before responding to any of the above requests. We do this to protect you and all of our users.</p>

<p>If you have a concern about how we have processed your request or your personal data, please make a first contact with us via our <a href="mailto:DPO@refreshconnections.com">Data Protection Officer</a>.</p>

<p>If we haven&#8217;t been able to resolve your concern, you have the right to make a complaint at any time in the UK to the <a href="http://www.ico.org.uk/">Information Commissioner&#8217;s Office (ICO)</a>, who is the UK supervisory authority for data protection issues. If you live in the European Union (EU) or European Economic Area (EEA), you should contact your local <a href="https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080">Data Protection Regulator</a>. Outside the UK and the EU, you may have the right to lodge a complaint with your local privacy or data protection regulator.</p>

<h1>10. Procedures for Obtaining and Withdrawing Consent For Data Processing</h1>

<p>As discussed above and summarized here, there are different ways you give consent to Refresh Connections to process your personal data, and there are different ways to withdraw that consent.</p>

<h2>Consent You Give During Refresh Connections Account Creation</h2>

<p>When you create your account, you may choose to consent to give Refresh Connections personal data such as your geo-location to make it easier to find friends, and you may choose to fill out profile features such as gender, religion, ethnic background, sexual preferences, photos, and others. You may control and withdraw your consent the display of any of these profile attributes in the Profile section of the Me tab of your app, except your real name, age, and email address which are necessary for the app to function. You may also email us with any questions or needed changes at <a href="mailto:help@refreshconnections.com">here</a>.</p>

<p>When you create your account, you have the option to consent to receive email messages from us about the app, including newsletters containing information on how to use the app, new features, and information about any other opportunities we might be able to offer including opportunities with third parties. You may withdraw consent for these update and marketing emails at any time by going to Profile Actions in the Settings Section of the Me tab on your app, by clicking the unsubscribe link or opt-out mechanism on any email that we send, or by emailing us <a href="mailto:help@refreshconnections.com">here</a>.</p>

<h2>Consent You Give By Accessing And Using the App and Website</h2>

<p>When you access and use the app or website, you consent to the processing, transfer, and storage of information in and to the United States and other countries, where you may not have the same rights as you do under local law. You may withdraw consent at any time by deleting your account or emailing our <a href="DPO@refreshconnections.com">Data Protection Officer</a>.</p>

<p>By using the app with app notifications set to on in your device, you consent to receiving push notifications from Refresh Connections. You can deactivate these messages at any time by changing the notification settings on your mobile device.</p>

<h2>Consent Age</h2>

<p>You must be 18 years of age to consent to use the service. Refresh Connections does not knowingly allow account creation or knowingly collect any information about or market to anyone under the age of 18. We will move to terminate any account we find out is created by, registered to, or used by anyone under 18 years of age.</p>

<h1>11. Automated Decision-Making</h1>

<p>We started Refresh Connections because the Covid Pandemic revealed that too many of our connections were too willing to share a dangerous communicable disease with us without consent, transparency, warning, or caring about the consequences. So the most important and automatic (although not automated) part of any and every matching algorithm on Refresh Connections is delineated in our Terms of Service: users must practice Covid Conscientious precautions.</p>

<p>Your biggest control point in finding the best matches for connections and information on the Refreshments Bar community forum is by using the filters we give you to sort people and information by characteristics.</p>

<p>Because everyone approaches Covid Conscientious Behaviors along their own risk continuum, our goal is to also develop automated algorithms to help you make the most relevant connections for chats on the app and for conversations taking place at the Refreshments Bar community forum. The personal data you provide for any algorithm we develop comes first from the information you provide about yourself in the Profile section such as age, location, gender, and your goals for making connections or using the Refreshments Bar community forum. Next comes information about how you access and use the app and your profile status so we can connect people who are active, ready, and available to meet you. And if you want to meet people that are nearby we will use the information you share with us about your location, whether that&#8217;s from the app&#8217;s geo-location or your manually entering in your coordinates.</p>

<p>As discussed in Section 4. Personal Data We Collect and Section 5. How We Use Information, the legal bases for creating any matching algorithm for connections and the Refreshments Bar community forum are the Terms and Conditions contract where we agree to help you find connections and information, the legitimate business interests we have in helping those connections be efficient and successful, and your consent when providing the information we use in your profile.</p>

<h1>12. Security and Moderation Measures</h1>

<p>We believe that security and moderation are the cornerstones of a successful community.</p>

<p>Multiple security points have been discussed in Section 6 which delineates our obligations in reporting emergencies and safety and criminal issues with law enforcement, and in Sections 7 and 8 which delineate the transfer and storage of your personal data.</p>

<p>Additionally, Refresh Connections also combines automated and human moderation to review Terms and Conditions violations in both accounts and messages. Users have robust blocking and reporting mechanisms in Chats and on the Refreshments Bar community forum that notify us and allow us to assess and make decisions with human input within 24 hours of notification. If an account or message is found to be in violation of our Terms and Conditions of Use, then based on the degree of the violation steps taken could include marking the message sensitive, deleting the message, warning the user, restricting access to the user, blocking the user for a period of time, or canceling the user&#8217;s account. Affected users can contact Refresh Connections to contest the decision.</p>

<p>Please see detailed instructions on how to report messages and users in <a href="https://refreshconnections.com/communitysafety">Refresh Connections Community Safety</a>.</p>

<p>If anyone posts in violation of our Terms and Conditions, including minimization of the consequences of Covid or any other communicable disease, we reserve the right to restrict, suspend, and/or terminate access to that person&#8217;s account.</p>

<h1>13. Updates to Privacy Policy</h1>

<p>We may update or change this privacy policy from time to time which will be reflected in the date and version. If changes are significant in our sole discretion, we may also notify you by a notification in the app or via your email address that you use for login. We encourage you to stay informed about our privacy practices. By using the service after the any new version goes into effect, you agree to be bound by the updated policy.</p>

<h1>14. Contact information</h1>

<p>For further information about our privacy practices, please contact us at:</p>

<p>Email: <a href="mailto:help@refreshconnections.com">General Information and Support</a></p>

<p>Privacy Specific Inquiries: <a href="mailto:DPO@refreshconnections.com">Data Protection Officer</a></p>

<p>Address: General Inquiries: Refresh Connections, LLC, 64 Bleecker Street, Suite 226, New York, New York 10012.</p>

<p>Address: Privacy Inquiries: Refresh Connections, LLC, Attn: Data Protection Officer, 64 Bleecker Street, Suite 226, New York, New York 10012</p> </div>
        </div>



        )
  }
}

export default Privacy;


