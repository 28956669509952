import React, { Component } from "react";
import Fade from "react-reveal";



class Subscribe extends Component {
  render() {

    return (
      <div id="coming-soon">
        <ul className="social">
          <a href="/" className="button btn project-btn">
            <i className="fa fa-arrow-left"></i>Back
          </a>

        </ul>
        <div className="banner-text">

          <Fade bottom>
            <div className="img-container">
              <img src="./images/refresh-white-centered.png" href="/"></img>
            </div>
          </Fade>
          <Fade bottom>
            <h3 className="responsive-headline" >Not in your country yet?</h3>

          </Fade>
          <Fade bottom duration={1200}>
            <h3>Sign up for our email list now.</h3>
          </Fade>

          <div>
            <iframe id="subscribe-to-list"
  width="100%"
  height="800"
  src="https://eepurl.com/iJoRBg"></iframe>
            
          </div>
        </div>
      </div>)
  }
}

export default Subscribe;
