import React, { Component } from "react";



class CommunitySafety extends Component {
  render() {

    return (
<div>
<ul className="social">
                <a href="/" className="button btn project-btn">
                  <i className="fa fa-arrow-left"></i>Back
                </a>
                
              </ul>
      <div id="legal"><h1>Refresh Connections Community Safety</h1>

<h3>Version 1.12b, (Effective May 25, 2024)</h3>

<p>Our goal is for Refresh Connections to be a community where safety comes first.</p>

<p>Use Covid Conscientious behaviors, as discussed in our <a href="https://refreshconnections.com/principles">Member Principles</a>.</p>

<p>Exercise caution as you meet new friends, just as we&#8217;ve had to do both in the era before COVID through today. Meeting people is fun, but you need to be careful with people you don&#8217;t or are just getting to know. Always be cautious as you are getting to know people, whether online or in person, and wherever possible follow a progression from online conversations to meeting up in person as we discuss below. And remember, there is no foolproof method for staying safe - you need to be watchful and worried to make sure you aren&#8217;t falling into a dangerous situation.</p>

<p>The following guidelines start with your online account and how to report problem activity there. Then we discuss online activity. Finally we&#8217;ll discuss meeting up in person.</p>


<h2>What You Post</h2>

<p>Be careful about what you post online - including on the Refresh Connections app. Never share your exact address, place of work, schedule - and make sure this information isn't available on any social media sites you have linked in your profile (don't include that information on your social media even if you haven't shared the link on Refresh Connections, of course!). Be sure to review our <a href="https://refreshconnections.com/privacy">Privacy Policy</a> (particulary Section 6) about your profile's visibility on the app.</p>

<h2>Keep Your Account Safe</h2>

<p>Choose a strong password that is unique for Refresh Connections. We recommend using a password manager for this purpose. Avoid signing in on your account on someone else&#8217;s phone or a public computer.</p>

<p>Refresh Connections will never call, text, or send an email asking for your password. If you receive any of those, report them immediately. Also, never share any text message code we send you for two-factor authentication with anyone.</p>

<h3>How to Report</h3>

<h4>How to Report a User in a One-on-one Chat</h4>

<p>In one-on-one chats,</p>

<ul>
<li> Select the name of the person you are reporting at the top of the screen</li>
<li> Select the bug icon on the lower left side of your app.</li>
<li> Choose Report</li>
<li> Fill out the Report Submission Form</li>
<li> A Refresh Connections member will address the situation within 24 hours.</li>
</ul>

<h4>How to Report a User in a Group Chat</h4>

<p>In group chats,</p>

<ul>
<li> Select the name of the group at the top of the screen,</li>
<li> Select Leave the Group</li>
<li> Go to your Me tab</li>
<li> Go to Help</li>
<li> Fill out the Report Submission Form</li>
<li> A Refresh Connections member will address the situation within 24 hours</li>
</ul>

<h4>How to Report a Post in the Refreshments Community</h4>

<ul>
<li> Go to the upper right ellipsis button at the top right of the post you are reporting</li>
<li> Choose &#8220;Report Post&#8221;</li>
<li> Fill out the Report Submission Form</li>
<li> A Refresh Connections member will address the situation within 24 hours</li>
</ul>

<h4>How to Report a Comment in the Refreshments Community</h4>

<ul>
<li>Slide left on the comment you want to report</li>
<li>Click the exclamation mark icon</li>
<li>Fill out the Report Submission Form</li>
<li>A Refresh Connections member will address the situation within 24 hours</li>
</ul>

<h3>Report Covid Minimizers</h3>

<p>Our <a href="https://refreshconnections.com/terms">Refresh Connections Terms of Service</a> prevents people who don&#8217;t believe in taking Covid precautions from joining the service. If you find that a member is minimizing, denying, encouraging the spread of Covid or other communicable diseases, or otherwise operating as a bad actor—then report them. We will investigate and take action and can remove their presence from Refresh Connections.</p>

<p>Additionally, we recognize that people at Refresh Connections are happy to talk about Covid and the way they approach the pandemic. If someone does not want to reveal their approach, or seems not to understand how to protect themselves and others, that is a red flag and not a teaching moment. This is a violation of our terms and should be reported.</p>

<h3>Report When You See Our Terms of Service Broken, or For Any Worrisome Behavior</h3>

<p>Covid elevates the stakes with safety because a person that ignores Covid Conscientious behaviors puts not only themself at risk but also all the people around them and the people who are exposed to them.</p>

<p>If somebody appears to be a bad player, report them. If somebody appears to have signed up to the service because they have an agenda against what Refresh Connections stands for, report them. If someone engages in troll like behavior, report them.</p>

<p>Here are a few other examples of behaviors that should be reported:</p>

<ul>
<li> Anyone asking for money, for whatever reason. Scammers often cite charities or emergencies as reasons you need to give now. Don&#8217;t do it.</li>
<li> Anyone pushing you to leave the app right away and move to another platform. This is a red flag.</li>
<li> Anyone who reveals they are underage or who appears to be lying about their age.</li>
<li> Anyone who has been dishonest with their profile. If someone can&#8217;t be honest with their profile, then they can&#8217;t be trusted to be Covid Conscientious or honest in other areas.</li>
<li> Anyone who harasses, bullies, threatens, or leaves obscene messages or photos.</li>
<li> Anyone using the service to sell or promote something.</li>
</ul>

<p>You can report any concerns about suspicious behavior from any profile page or messaging window by emailing us <a href="mailto:report@refreshconnections.com">here.</a></p>

<p>For more information, please review our <a href="https://refreshconnections.com/principles">Member Principles</a>.</p>

<h3>Don&#8217;t Connect Outside of Refresh Connections With New Friends</h3>

<p>While you are getting to know someone, stay on the Refresh Connections app. Engage in one-on-one conversations, but also make use of group conversations with friends you have made on the app.
How a potential friend or date acts around other friends is critical to understanding a relationship both online and in person. Refresh Connections also has the Refreshments Bar, which is a community board where people can post. You can find the username used there by any friend you have connected with and make sure to review their comments and posts to further get to know them.</p>

<p>Remember, people who want to take advantage of you will make a move to move the conversation off of Refresh Connections too soon. They will try to avoid meeting and being involved with your other friends. And they will move to talk to you in ways that reveal personal information, such as your phone number by trying to get you to talk or message them. There is no reasons to move to these forms of communication early in a relationship before you know them well.</p>

<h2>Never Send Money and other activities you should never engage in.</h2>

<p>Getting naive people to send money over a social network is a well known scam. Don&#8217;t fall for it. Never send money. Scammers have many reasons for requesting money from you, and they will push and pressure you with well honed stories of emergencies and family needs. The key is to recognize they are asking for money and financial information. If someone does that, your next step is to report them <a href="mailto:report@refreshconnections.com">here</a>. Scams are so common online that the US Federal Trade Commission has a full website and even a video to watch with tips and tricks on how to recognize and avoid scams. We recommend reviewing  <a href="https://www.consumer.ftc.gov/articles/0004-online-dating-scams">the FTC website</a>.</p>

<p>It is also important to never share personal information such as your social security number, home address, work address, and other information that can identify you away from the Refresh Connections app. New friends don&#8217;t need to know the details of your health or where and how often you go to the doctor. Don&#8217;t tell new friends your work schedule. Also don&#8217;t give new friends details about your family or your children.</p>

<p>Be aware that Refresh Connections is an international community, and there may be people abroad who you connect with that serve as great online friends and champions of your Covid Conscientious behaviors. But watch out for bad actors, particularly if someone claims to be from your country and needs to find a way back home while asking for money or help to make it happen. Again, any request for money is a red flag and should be reported.</p>

<h2>Meeting in Person</h2>

<p>It&#8217;s exciting to meet new people, particularly ones that share interests. This is particularly true during the pandemic when meeting someone that takes their health seriously even as all too many people don&#8217;t. As discussed above, get to know the other person before agreeing to move off the Refresh Connections platform.</p>

<p>Meeting someone in person is similar to how we recommend you use the Refresh Connections app.</p>

<p>If you can, involve another friend—perhaps even one you already know that is on Refresh Connections. Take advantage of the group conversation feature to introduce everyone, then use that to safely move to getting to know the new friend away from Refresh Connections. Starting with a group video call can be a great first step. Don&#8217;t be afraid to ask questions to screen for any red flags or personal dealbreakers.</p>

<p>After agreeing to meet in person, use your family and friends to keep safe when you meet a new person. Arrange first interactions to be in group activities with them. As you move to initial one-on-one activities tell them your plans and plan to have them touch base with you to make sure things go well during the interaction. Have your cell phone charged and with you at all times.</p>

<p>It&#8217;s safer to have your first meetings outside in a public place with other people around. Being Covid Conscientious makes this easy - and you can make sure the public place has lots of air movement. Don&#8217;t ever meet at your home or your new friend&#8217;s home because they are too remote. Never agree to meet at any other place that is remote either. If your friend becomes unhappy or pushes you otherwise, that&#8217;s a red flag. End contact with that person.</p>

<p>Make sure you are in control of how to get to and from the event you are planning. Always keep your purse, wallet, and car keys with you at all times. Don&#8217;t rely on your new friend in case things don&#8217;t go well and you need to end things early. Have a friend available to pick you up or as a backup if you drive yourself. Use Uber/Lyft as a backup only if you know they can be quickly available.</p>

<p>Don&#8217;t use drugs or drink past your known limit as you get to know your new friend. If your friend pushes you to partake, then end the event. Also, be aware that bad players can use roofies or other sedatives in drinks as a plan to make sexual assault easier. Do not leave your drink unattended ever.</p>

<p>As mentioned above, be ready to end the event early if you&#8217;re feeling uncomfortable. If your instincts are telling you something is off or you feel unsafe, ask a worker such as the bartender or server for help. If you feel you are in immediate danger, call 911 in the US or Canada, or your local law enforcement agency in other areas. </p>
</div>
</div>


        )
  }
}

export default CommunitySafety;


