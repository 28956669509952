import React, { Component } from "react";
import Fade from "react-reveal";

class Change extends Component {
  render() {

    return (
      <section id="marketing" class="bg-blue">
        <div class="graphic relative">
          <img class="offside-top-left" src="./images/mask-white-border.png" ></img>
        </div>
        <div class="main left">
          <br/>
          <h3 class="top-left-padding right">We care that Covid continues to change so much about life.</h3>
          <br/>
          <h3 class="right">We care that Covid creates more risk for the LGBTQIA+ community, people of color, the disabled, and other underrepresented communities.</h3>
          <br/>
          <h3>We care that Covid has exposed ableism, hubris, and an absence of compassion.</h3>
          <br/>
          <h3>We recognize the need for bringing friends, family, loved ones, and those working for change together into a community.</h3>          
          </div>
      </section>
    );
  }
}

export default Change;
