import React, { Component } from "react";
import AwesomeSlider from 'react-awesome-slider';

import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);




class Science extends Component {
  render() {
    if (!this.props.data) return null;

    return (
        <section id="science">
       <AutoplaySlider 
        className="sliders"
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={3000}
       >
        <h3>We don’t share air.</h3>
        <h3>It's time to upgrade from the baggy blues. </h3>
        <h3>You don’t get Long Covid without getting Covid.</h3>
        <h3>We love air purifiers and fresh air.</h3>
        </AutoplaySlider>
      </section>)
  }
}

export default Science;
