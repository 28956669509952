import React, { Component } from "react";
import AwesomeSlider from 'react-awesome-slider';

import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);




class Care extends Component {
  render() {
    return (
        <section id="care">
       <AutoplaySlider 
        className="sliders"
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={3000}
       >
        <h3>There's always someone masking wherever I go.</h3>
        <h3>See a mask, wear a mask.</h3>
        <h3> We care about each other.</h3>
        </AutoplaySlider>
      </section>)
  }
}

export default Care;
