import React, { Component } from "react";
import Fade from "react-reveal";

class WeCare extends Component {
  render() {

    return (
      <section id="marketing" class="bg-blue">
        
        <div class="main center">
          <h2> Right now Covid Conscientious friends are talking and making plans on Refresh.</h2>
          <div class="graphic ">
            <img src="./images/twohello.png" ></img>
          </div>     
          </div>
      </section>
    );
  }
}

export default WeCare;
