import React, { Component } from "react";



class CookiePolicy extends Component {
  render() {

    return (
<div>
<ul className="social">
                <a href="/" className="button btn project-btn">
                  <i className="fa fa-arrow-left"></i>Back
                </a>
                
              </ul>
      <div id="legal"><h1>Cookie Policy</h1>

<h3>Version 1.12, (Effective May 19, 2024)</h3>

<p>We want you to understand how we utilize cookies at Refresh Connections—and the options you have in managing them.</p>

<p>If you are looking for how we collect and use your personal information unrelated to cookies, you&#8217;ll find it in our <a href="https://refreshconnections.com/privacy">Privacy Policy</a>.</p>

<h3>Cookies defined</h3>

<p>Cookies are small snippets of text (small files) potentially used by an app you are employing to browse - or access information - on the internet. A usual cookie contains three core pieces of information: its originating domain name (Internet location), its expiration date, and a unique number or identifier. It may optionally contain information about your device, such as browser history or activities you choose when using a website&#8217;s services.</p>

<h3>Different types of cookie categories</h3>

<p><strong>Party</strong></p>

<p>Cookies can be categorized as first-party cookies or third-party cookies. First party cookies are those used by the designer of the website you are visiting. So in regard to this policy document, first-party cookies are created and placed on your device directly by us. For example, we can use first-party cookies to adapt our website to your browser&#8217;s language preferences and to better understand your use of our website. Third party cookies are placed on your device by our partners and service providers. For details, see &#8220;controlling cookies&#8221; below.</p>

<p><strong>Duration</strong></p>

<p>The duration of a cookie is defined as session, which is created when you visit a website and only lasts until you leave; or as a persistent cookie, which lasts beyond your stay on the website and can be present upon your return. Session cookies have a variety of uses, including to learn more about how and where you go on our website in a session, and to help us improve website efficiency. Persistent cookies can be used to help you quickly sign in to our website again, for analytical purposes, and for other reasons, described below.</p>

<h3>Other cookie-like tracking technologies</h3>

<p>Refresh Connections may also use other technologies just like we use cookies. These technologies can include web beacons (pixels), tracking URLs or software development kits (SDKs). Web beacons are tiny graphics files that contain a unique identifier used to track email opening and site visitation rates. Tracking URLs are links that help us understand where the traffic to our webpages comes from. SDKs are small pieces of code included in apps, which function like cookies and web beacons.</p>

<p>For simplicity, we will call all of these technologies &#8220;cookies&#8221; in this document.</p>

<h3>What do we use cookies for?</h3>

<p>Cookies are used by software companies to enhance their services, including allowing advanced services, more efficient services, and more secure services. For example, a cookie may allow you to retain your preferences on a website or app, help us recognize how you use our app or website, and allow us to measure whether a marketing campaign is worthwhile.</p>

<p>When you use our services, some or all of the following types of cookies may be set on your device.</p>

<h3>Cookie type</h3>

<h4>Essential</h4>

<p>Essential cookies are core to our providing you services, such as allowing you to log in to the site, remember your preferences so you don&#8217;t have to approach the site or app as a new user every time you log in and to keep you safe.</p>

<h4>Analytics</h4>

<p>Analytic cookies help us measure activity on the website to understand how to customize and improve our services for you.</p>

<h4>Advertising</h4>

<p>Advertising cookies help us run and measure effective campaigns that are interesting and relevant to you. They let us know how much traffic we are creating, how often each individual ad runs, how and which ad displays on a particular device, and whether you found the ad useful.</p>

<h4>Social networking</h4>

<p>These cookies let you share content with other websites and services. They also might be used to measure our marketing effectiveness.</p>

<h3>Cookie management</h3>

<p>You can control cookies in different ways, and the changes you make to them may result in the site not working as well for you, such as eliminating your ability to personalize your settings, or even not working at all, such as stopping the website or app from loading.</p>

<h4>Tools in the Refresh Connections App or Website</h4>

<p>Cookie preferences can be set in the account settings section of the app or website. As explained above, we refer to some cookie-like technologies as cookies in this document, so SDK changes provided in your Google or Apple app may not be referred to as cookies within the app settings options.</p>

<h4>Browser and device controls</h4>

<p>Most web browsers you can use to access the Refresh Connections websites allow you to manage cookies. Look to the cookie setting portion of your chosen browser for options it provides.</p>

<p>You may also be able to reset device identifiers or opt out from having identifiers collected or processed within the settings options of your Google or Apple phone or device.</p>

<p>Opting out does not mean you will not see advertising—it means you won&#8217;t see personalized advertising from the companies that participate in these opt-out programs. Also, if you delete cookies on your device after you&#8217;ve opted out, you will need to opt out again.</p>

<h3>Google Cookies</h3>

<p>Some features of our website and some Refresh Connections, LLC services rely on the use of Google™ Maps API Cookies. Such cookies will be stored on your device.</p>

<p>When browsing this website and using the services relying on Google Maps API cookies, you consent to the storage and collection of such cookies on your device and to the access, usage and sharing by Google of the data collected thereby.</p>

<p>Google manages the information and your choices pertaining to Google Maps API Cookies via an interface separate from that supplied by your browser. For more information, please go <a href="https://www.google.com/policies/technologies/cookies">here</a></p>

<p>We use Google Analytics—a Google service that uses cookies and other data collection technologies to collect information about your use of the website and services, in order to report website trends.</p>

<p>For more information on how Google collects and processes data, visit <a href="https://policies.google.com/technologies/partner-sites">here</a>. You can opt out of Google Analytics by downloading the Google Analytics opt-out browser add-on <a href="https://tools.google.com/dlpage/gaoptout">here</a> and opt out of Google&#8217;s ad personalization <a href="https://adssettings.google.com/">here</a>.</p>

<h3>Questions?</h3>

<p>If you have questions about our use of Cookies, please contact our <a href="mailto:DPO@refreshconnections.com">Data Protection Officer</a>.</p>

<p>Or by post at:</p>

<p>Data Protection Officer
Refresh Connections, LLC
64 Bleecker Street, Suite 226
New York, New York 10012</p></div>
        </div>



        )
  }
}

export default CookiePolicy;


