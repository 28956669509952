import React, { Component } from "react";



class MemberPrinciples extends Component {
  render() {

    return (
<div>
<ul className="social">
                <a href="/" className="button btn project-btn">
                  <i className="fa fa-arrow-left"></i>Back
                </a>
                
              </ul>
      <div id="legal"><h1>Refresh Connections Member Principles:</h1>

<h3>Version 1.13, (Effective June 12, 2024)</h3>

<p>Refresh Connections is your place to make <strong>Covid Conscientious</strong> Friends, find Covid Conscientious information, and have Covid Conscientious discussions.</p>

<p>People want to forget about Covid so badly they are willing to ignore an ongoing pandemic. In doing so they have embraced getting infected again and again.</p>

<p>Ignoring the impact of Covid comes at a great cost. The great majority are sick more often and sicker than they ever imagined. There is a growing toll of ruin from Long Covid.</p>

<p>The cost and ruin isn&#8217;t limited to disease. It has also spread to relationships. After all, it&#8217;s hard to maintain a relationship when one side doesn&#8217;t care if they infect the other even in the places we hold so dear: like cancer wards, doctors' offices, jobs, public transit, and grocery stores—or even in your own home.</p>

<p>It has become lonely for the people who don&#8217;t want infection and don&#8217;t want to spread infection to their loved ones and friends. </p>

<p>We think it&#8217;s time for a change.</p>

<p>We think it&#8217;s time to create a new place for people who want to join a Covid Conscientious community.</p>

<p>A place where we leave the power plays and disinformation of the minimizers behind. Where no one shames us for following science and pursuing a better way.</p>

<h2>Welcome to Refresh Connections</h2>

<p>The Refresh Connections community starts with friendship.</p>

<p>When common ideas bring two people together, important things can happen. Covid Conscientious friendships foster Covid Conscientious groups. And Covid Conscientious groups give community a power that can do so much more.</p>

<p>We want to build a community that starts changing the way things work, starting at the local business level and then moving beyond.</p>

<p>Our goal at Refresh Connections is to build the technology that fosters the community that creates a new, safer normal for everybody.</p>

<p>Here are the principles that guide us in building Refresh Connections and its technology, and our expectations for the Refresh Connections Community.</p>

<h1>Principles for the Refresh Connections Community</h1>

<h2>Prioritize Covid Conscientious Behaviors</h2>

<p>Why use Covid Conscientious and not Covid Safe? Safety means free from harm. Taking precautions against Covid lowers your risk but doesn't get rid of it completely. Describing behaviors as conscientious acknowledges this risk and builds on using good judgement to make as safe a decision for everyone as possible based on the specific situation a person finds themselves in. </p>

<p>No one has a lock on being Covid Conscientious. It&#8217;s something we aspire to in our behaviors.</p>

<p>We use respirators when we share air. We stay up-to-date on vaccines. We test. We live transparently. We search for physicians who prescribe recommended accepted treatments if we become ill. If we see someone wearing a respirator or mask, even if it&#8217;s outdoors with no one around—we put our respirator on in support. We believe you should, too.</p>

<p>We also believe you shouldn&#8217;t have to deal with risky Covid behaviors while making friends—or while building a new, Covid Conscientious world.</p>

<p>We understand that not everyone can afford to stay safely protected. Some are forced into risk by their employers. The same Covid Conscientious behavior applies in how we approach helping these people—and when mutual, allows us to meet safely, with the right protections, in the right places, even if just online. </p>

<p>We acknowledge that a growing Covid Conscientious community will attract new members who, while possessing a desire to access support services or try to be safer, may not be aware of how our approach to Covid Conscientiousness is put into practice. Given that relationships start here online, we should approach new members with conscientiousness as we guide them to further their awareness and make resources available. After all, our community needs to grow to change the world into a more Covid Conscientious place, and each new member powers our movement. </p>

<h2>Facilitate Safety</h2>

<p>Making friends in the Refresh Connections community should be a safe experience. What makes our app different is that we prioritize Covid safety in addition to being careful in the pre-pandemic way as you make new connections on the app.</p>

<h2>Facilitate Transparency</h2>

<p>Our goal is to make it easy for people to communicate their risk status in a Covid Conscientious way.</p>

<p>For example, here are two scenarios that imply different levels of safety. A Covid Conscientious person knows the difference and tells their connections before meeting in person.</p>

<ul>
<li> Scenario 1: I have been masking at work and in public places and I have no symptoms.</li>
<li> Scenario 2: I&#8217;ve isolated by myself from people for 5 days and have taken negative lateral flow tests every other day during that period for a total of 3 consecutive negative tests.</li>
</ul>

<p>Our goal with the app is to make this sort of transparency easier as new friends start to meet up in real life with other new friends.</p>

<h2>Welcome New Members and No Shaming</h2>

<p>We create tools so potential friends can find each other based on interests and behaviors. We aren&#8217;t here to make decisions on whether subjective things are fact or fiction, but we do take Covid and other safety behaviors seriously, and we will work to ensure our users are not putting others in jeopardy or inflicting harm. We recognize any of us can be impacted by the next Covid infection, whether that infection is in ourselves or a friend or a family member: Let&#8217;s keep the minimizers and those who shame safety out of the Refresh Connections community.</p>

<p>We are aware that every member's life is different, and every member must determine for themselves the level of protective Covid behaviors that they adopt in general, and in any specific situation. </p>

<p>It is not minimizing or safety shaming to be seen without a mask in some situations, for example, outdoors. Others might be required to go maskless for periods at work. And in a new and concerning development, any one of us might be compelled in North Carolina to take a mask off by the very minimizers and safety shamers we don't include in this community.</p>

<p>Covid Conscientiousness takes into account the situation—and then strives to be transparent about it as discussed above. Communication and understanding help us to start getting together in persons again.   </p>

<h2>Avoid Biases</h2>

<p>We are committed to building a community that is genuine and needs-responsive. That means building an inclusive environment for people excluded by society. We welcome people here that are committed to avoiding that next Covid infection—who might also need to be sorting out how to live with the consequences of a prior one.</p>

<p>We will build our tools and policies in support of this diversity and inclusion, particularly as it pertains to safety, security, and the power we are creating with the Refresh Connections community.</p>

<h2>Foster Trust and Respect as Part of Conscientiousness</h2>

<p>For Refresh Connections to have power in community, relationships have to be meaningful. And all meaningful relationships are built on trust and respect.</p>

<p>We believe trust and respect can&#8217;t be demanded or expected. Rather they need to be earned. We build our technology and services to foster that among the community. And we follow those principles ourselves.</p>

<h2>Make friends with those aspiring to be Covid Conscientious</h2>

<p>We created Refresh Connections to help you do this. So go to your Picks section and get started!</p>

<h2>Strive to be as Covid Conscientious as Possible</h2>

<p>Wear a respirator when you share air. Stay up-to-date on vaccines. Test and be honest about your Covid exposures.</p>

<p>Every Covid exposure puts you at risk of long term illness. Respect that risk—and the required safety to avoid it.</p>

<h2>Keep learning</h2>

<p>Covid Conscientious people engage. They keep up with and follow the science. Ongoing learning makes it easier to tune out bad actors and their bad information.</p>

<h2>Wear a respirator when risk is present</h2>

<p>Respirators again? Absolutely. Because respirators are both courageous and conscientious.</p>

<p>Be courageous and protect your community.</p>

<p>Be cautious and protect yourself.</p>

<h2>Vaccinate</h2>

<p>Covid vaccination may not yet protect you from becoming infected with or spreading Covid, but it has been shown to reduce the seriousness of the illness and to decrease your chances of catching Long Covid.</p>

<h2>Don&#8217;t Bet Your Health When Ruin Is An Outcome</h2>

<p>Anyone can get Long Covid. It can happen to you with your next Covid infection. it can happen to with your next infection whether the chances of getting it are 5% or 15%. It can happen to you with your next infection if you are vaccinated. And it can happen to you if you&#8217;ve skated by with your last infection.</p>

<p>So be Covid Conscientious. Each person lives with their own risk continuum but understand the risk when ruin is in the game. The sequence of Long Covid at this point is one way for all too many people.</p>

<h2>Surround yourself with people striving to practice Covid Conscientious behaviors</h2>

<p>Too many of us have felt our social circles dwindle during the pandemic. Refresh Connections is built to help you create new social circles of Covid Conscientious friends.</p>

<p>Don&#8217;t be afraid to make new friends!</p>

<h2>Respect Consent</h2>

<p>Consent needs to be communicated freely and clearly. It can be also be implied. We expect Refresh Connections members will not promote emotional, physical, or psychological abuse, harm, harassment, hate, or discrimination - including unwanted sexual advances, comments, or imagery. This includes posting such content to your profile.</p>

<p>Refresh Connections also expects consent to be followed and choices to be honored with Covid Conscientious behaviors. Don&#8217;t safety shame.</p>

<h2>Respect Boundaries</h2>

<p>Relationships should stay on Refresh Connections unless both people—or everyone in a group—have communicated that it is appropriate to move the conversation elsewhere. Making friends with someone on Refresh Connections should not be an invitation to contact that person on outside social media, particularly if that person has unmatched/disconnected with you for whatever reason.</p>

<p>Covid boundaries also need to be followed. Don&#8217;t harass or bully a person to take off a respirator if that person has assessed that it is risky. Actually, put yours back on for that person! Don&#8217;t use peer pressure to encourage someone to enter a Covid risky place - like an indoor restaurant or a crowded outdoor event.</p>

<p>Keep people&#8217;s information private. No individual needs to justify why they embrace Covid Conscientious behaviors, but if a person shares a reason why they are—that is their privilege to share and not yours to share with others. Welcome new members who have joined to become Covid Conscientious and help them surround themselves with friends who follow Covid Conscientious behaviors.</p>

<h2>Embrace Honesty</h2>

<p>Provide your real name or nickname, date of birth, and at least one photo of your face if you are taking part in the friends and dating side of the app. If you aren't ready to do so, then sign up for the Refreshments Bar side of the app and participate there until you feel more comfortable. Don't sign up for duplicate accounts or create an account if you are not eligible in the terms. Deceiving others is unfair and a cause for exclusion from the Refresh Connections community.</p>

<h2>Pursue Meaningful Relationships</h2>

<p>Put in the effort to let your new friends know who you are and what is meaningful to you. Authenticity leads to respect, which along with trust form the basis of enduring relationships. Completing your profile creates a number of conversation starters. </p>

<p>We respect the many different forms meaningful relationships can take and encourage everyone to invest in friendships that can last.</p></div></div>

        )
  }
}

export default MemberPrinciples;


