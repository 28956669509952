import React, { Component } from "react";
import Fade from "react-reveal";

class Movie extends Component {
  render() {

    return (
      <section id="marketing" >
        <div className="movie">
          <img src="./images/WeCare20.gif" ></img>
        </div>
      </section>
    );
  }
}

export default Movie;
