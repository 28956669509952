import React, { Component } from "react";
import Fade from "react-reveal";

class NewPlace extends Component {
  render() {

    return (
      <section id="marketing" class="bg-navy">
        <div class="main center">
        <h2  class="white"> Refresh Connections starts with Covid Conscientious friendships</h2>
        <div class="graphic">
          <img src="./images/twofer.png" ></img>
        </div>
        <h2  class="white"> Builds with Covid Conscientious groups</h2>
        <div class="graphic">
          <img src="./images/fourinarow.png" ></img>
        </div>
        <h2  class="white"> And grows into a community that fosters the Covid Conscientious change we all need!</h2>
        <div class="graphic">
          <img src="./images/pyramid.png" ></img>
        </div>
        
        </div>
      </section>
    );
  }
}

export default NewPlace;
