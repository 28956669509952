import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";


class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const description = this.props.data.description;
    const topdescription = this.props.data.topdescription;


    let config = {
      body: "./images/slice6.png",
      position: {x:0,y:932,width: window.innerWidth,height:0},
      // color: ["random", "#ff0000"],
      num: [0,2], //num of flowers
      rps: 1, // speed
      radius: [2, 9],
      life: 14, // how long on screen
      v: 2,
      tha: .5,
      rotate: [1, 1.5],
      alpha: 1,
      // scale: [1, 0.6],
      cross: "bround", // cross or bround
      random: null,  // or null,
      g: -.15,    // gravity
      // f: [0, 0], // force
      // onParticleUpdate: (ctx, particle) => {
      //     ctx.beginPath();
      //     ctx.rect(particle.p.x, particle.p.y, particle.radius * 2, particle.radius * 2);
      //     ctx.fillStyle = particle.color;
      //     ctx.fill();
      //     ctx.closePath();
      // }
    }

    return (
      <header id="home">
        <ParticlesBg bg={true} type="custom" config={config} />

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>
            {/* <li>
              <a className="smoothscroll" href="#friendships">
                Friendships
              </a>
            </li> */}
            {/* <li>
              <a className="smoothscroll" href="#dating">
                Dating
              </a>
            </li> */}
            {/* <li>
              <a className="smoothscroll" href="#community">
                Community
              </a>
            </li> */}
            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            
            <Fade bottom>
              <img src="./images/refresh-white-centered.png"></img>
            </Fade>
            <Fade bottom>
              <h2 className="responsive-headline" >&nbsp;</h2>
              {/* <h2 className="responsive-headline" >{topdescription}</h2> */}


            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}</h3>
            </Fade>
            <Fade bottom duration={2000}>
              <div id="marketing" className="no-bg">
            <div className="button-row">
              
              <div className="button-col">
                <a href="https://apps.apple.com/us/app/refresh-connections/id6502037766" className="button btn">
                  <i className="fa fa-apple"></i>&nbsp; iOS
                </a>
              </div>
              <div className="button-col" >
                <a href="https://play.google.com/store/apps/details?id=com.refreshconnections.app" className="button btn">
                  <i className="fa fa-android"></i>&nbsp;Android
                </a>
                
                </div>
                </div>
                </div>
            </Fade>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#marketing">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
