import React, { Component } from "react";
import $ from "jquery";
import "./App.css";
import Android from "./Components/Android";
import Terms from "./Legal/Terms";
import Privacy from "./Legal/Privacy";
import CookiePolicy from "./Legal/CookiePolicy";
import MemberPrinciples from "./Legal/MemberPrinciples";
import CommunitySafety from "./Legal/CommunitySafety";
import FAQs from "./Components/FAQs";
import Home from "./Components/Home";
import Ios from "./Components/Ios";
import Tips from "./Components/Tips";
import AboutUs from "./Marketing/AboutUs";
import Subscribe from "./Components/Subscribe";



import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from 'universal-cookie';


class App extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    this.state = {
      foo: "bar",
      resumeData: {},
      loginCookie: cookies.get('login')
    };
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();

  }

  handleSubmit(event) {
    event.preventDefault();
  }


  render() {
  
      return (
        <BrowserRouter>
          <div className="App">
          </div>
          <Routes>
            <Route index element={<Home data={this.state.resumeData.main} />} />
            <Route path="subscribe" element={<Subscribe />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="faqs" element={<FAQs />} />
            <Route path="faqsapp" element={<FAQs />} />
            <Route path="tips" element={<Tips />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="cookies" element={<CookiePolicy />} />
            <Route path="principles" element={<MemberPrinciples />} />
            <Route path="communitysafety" element={<CommunitySafety />} />
          </Routes>
        </BrowserRouter>
      );
  }
}

export default App;
